import { ref } from "@vue/composition-api";
import store from "@/store";

export const useShopFiltersSortingAndPagination = () => {
  const filters = ref({
    q: "",
    categories: [],
    brands: [],
    page: 1,
    perPage: 9,
  });

  const filterOptions = {
    categories: [
      "Outils électriques",
      "Perceuses",
      "Scies",
      "Meuleuses",
      "Engins de chantier",
      "Excavatrices",
      "Bulldozers",
      "Matériaux de construction",
      "Équipements de sécurité",
      "Matériel de levage",
    ],
    brands: ["Caterpillar", "Bosch", "Hilti", "Makita", "DeWalt"],
  };

  // Sorting
  const sortBy = ref({ text: "Stocks", value: "featured" });
  const sortByOptions = [
    { text: "Stocks", value: "tout" },
    { text: "Stock 1", value: "price-asc" },
    { text: "Stock 2", value: "price-desc" },
  ];

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  };
};

export const useShopUi = () => {
  const itemView = "grid-view";
  const itemViewOptions = [
    { icon: "GridIcon", value: "grid-view" },
    { icon: "ListIcon", value: "list-view" },
  ];

  // Pagination count <= required by pagination component
  const totalProducts = ref(null);

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  };
};

export const useShopRemoteData = () => {
  const products = ref([]);
  const fetchProducts = (...args) =>
    store.dispatch("storeManagement/fetchItems", ...args);

  return {
    products,
    fetchProducts,
  };
};
